// Sign Up Form On Landing Page

import React, { useState, useEffect, useContext } from 'react'
import { Context } from "../../../Context.js";
import { Amplify, Auth, Hub } from 'aws-amplify';
import useAuth from '../../useAuth'
import routeChange from '../../../routeChange.js'

const SignUpForm = () => {
    
    
    const { SignIn, SignUp } = useContext(Context);
    const [ isShowSignUp, setIsShowSignUp ] = SignUp;

    // Local states in SignUpForm Component
    const [errorSignUp, setErrorSignUp] = useState('')
    const [isConfirmCodeHidden, setIsConfirmCodeHidden] = useState(true);
    const [prompt, setPrompt] = useState('')
    const { currentUser } = useAuth();

    const [inputFields, setInputFields] = useState([
        {name: '', password: '', confirm_password: '', email: '', phone: '', confirmcode: ''}
    ])
    
    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
        
    }

    
    async function signUp(username, password, confirm_password, email, phone) {
        
      try {
          
          if (password != confirm_password) {
              console.log('Passwords dont match. Please try again.');
              setErrorSignUp( <font color="red"> {'Passwords dont match. Please try again.'} </font> );
              setIsShowSignUp(true)

              return
          }
          
        const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
            email,          // optional
//            phone,   // optional - E.164 number convention
            // other custom attributes
          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
          }
        });
        
        setIsConfirmCodeHidden(false);
        setIsShowSignUp(true);
        setErrorSignUp('');
        setPrompt('Check verfication code in email');

        console.log(user);
      } catch (err) {
        console.log('Error signing up:', err);
        setErrorSignUp( <font color="red"> {err.message} </font> );
        setIsShowSignUp(true)
      }
    }


    async function confirmSignUp(username, code) {
      try {
        let signUpStatus = await Auth.confirmSignUp(username, code);
      } catch (err) {
        setErrorSignUp( <font color="red"> {err.message} </font> );
        console.log('error confirming sign up', err);
      }
    }
    
    
    if (currentUser  !== null) {
        return (
                <button type='button' className='px-8 py-1 my-4 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700' onClick={() => {
                    routeChange('/dashboard');
                }}>Go To Dashboard</button>
        )
    } // End if statement
    
    return (
        <div className="flex login-form w-96 justify-center items-center">
        <div className="form-box solid">
            <form>
            
            {inputFields.map((input, index) => {
                return (<div className='items-center py-2 border-teal-500' key={index}>
                        
                        <div className="w-full text-teal-200">
                        <label
                          htmlFor="Email"
                          className="block text-sm font-semibold text-teal-200 text-left"
                        >Email:
                        </label>
                        <input
                        className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-b focus:outline-none'
                        type="email"
                        name='email'
                        value={input.email}
                        onChange={event => handleFormChange(index, event)}
                        
                        />
                        </div>
                        
                        <br></br>
                        
                        
                        <div className="w-full text-teal-200">
                        <label
                          htmlFor="Password"
                          className="block text-sm font-semibold text-teal-200 text-left"
                        >Password:
                        </label>
                        <input
                        className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-b focus:outline-none'
                        type="password"
                        name='password'
                        value={input.password}
                        onChange={event => handleFormChange(index, event)}
                        
                        />
                        </div>
                        
                        <br></br>
                        
                        <div className="w-full text-teal-200">
                        <label
                          htmlFor="confirm_password"
                          className="block text-sm font-semibold text-teal-200 text-left"
                        >Confirm Password:
                        </label>
                        <input
                        className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-b focus:outline-none'
                        type="password"
                        name='confirm_password'
                        value={input.confirm_password}
                        onChange={event => handleFormChange(index, event)}
                        
                        />
                        </div>
                        
                        <br></br>
                       
                        <input
                        className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-b focus:outline-none'
                        type="confirmcode"
                        name='confirmcode'
                        placeholder='Confirm Code'
                        value={input.confirmcode}
                        onChange={event => handleFormChange(index, event)}
                        hidden={ isConfirmCodeHidden }
                        
                        />
                        
                        
                        <br></br>
                        
                        
                        
                        </div>
                        )
            })}
            
            
            
            
            <div className='px-4 py-3 bg-gray-50 sm:px-6'>
            
            {/* Submit Sign Up*/}
            
            <div>{prompt}</div>
            
            <button type='button' className='px-8 py-1 my-4 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700' onClick={() => {
                
                if (isConfirmCodeHidden) { {/* inputFields[0].name = inputFields[0].email*/}
                    signUp(inputFields[0].email, inputFields[0].password,  inputFields[0].confirm_password, inputFields[0].email, inputFields[0].phone);
                    
                    window.lintrk('track', { conversion_id: 12804444 });
                }
                else { {/* inputFields[0].name = inputFields[0].email*/}
                    confirmSignUp(inputFields[0].email, inputFields[0].confirmcode);
                    window.lintrk('track', { conversion_id: 12804444 });
                }
                
            }}>
            {isConfirmCodeHidden ? "Sign Up" : "Confirm" }
            </button>
            
            {/* https://stackoverflow.com/questions/38521055/react-js-append-div-next-to-input-if-error  */}
            <div>{errorSignUp}</div>
            
            
            
            
            </div>
            
            </form>
        </div>
        </div>
    )

    
}

export { SignUpForm }

// Carousel Component

import React, { Component } from 'react'

export default class Carousel extends Component {
  static defaultProps = {
    defaultDescription: (
      <p>
        Download and print Blocktag tags using multiple print techniques, materials and form factors like barcode, QR, Augmented Reality (AR) markers. Custom configurations welcome.
        <span className='block italic text-gray-500'>&lt; Hover on tags for info &gt;</span>
      </p>
    ),
    data: [],
  }

  constructor (props) {
    super(props)
    this.state = {
      activeEl: null,
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  onMouseEnter (i) {
    return event => {
      this.setState({ activeEl: i })
    }
  }

  onMouseLeave (event) {
    this.setState({ activeEl: null })
  }

  render () {
    const { activeEl } = this.state
    const data = this.props.data

    return (
      <div>
        <div className='flex flex-wrap items-end py-12'>
          {this.props.data.map((c, i) => (
            <div
              key={`carousel_${i}`}
              className={(activeEl !== null ? 'hover:opacity-100 opacity-50' : 'pt-4 first:opacity-50 last:opacity-50') + ' w-1/3 text-center transition ease-out duration-700 transform hover:scale-125'}
              onMouseEnter={this.onMouseEnter(i)}
              onMouseLeave={this.onMouseLeave}>
              <img className='inline' style={{ maxWidth: '60%', maxHeight: '300px' }} src={c.img} />
            </div>
          ))}
        </div>
        <div className='justify-center w-full my-12 text-sm text-center'>
          <div className='max-w-lg mx-auto' style={{ height: '4rem' }}>
            {activeEl !== null
              ? <span dangerouslySetInnerHTML={{ __html: data[activeEl].description }} />
              : <span>{this.props.defaultDescription}</span>}
          </div>
        </div>
      </div>
    )
  }
}

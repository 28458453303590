import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'
import Layout from '../components/Layout'

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout includePreFooter={true}>
      <HomePageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        heading={frontmatter.heading}
        description={frontmatter.description}
        features={frontmatter.features}
        carousel={frontmatter.carousel}
        usecases={frontmatter.usecases}
        datafeed={frontmatter.datafeed}
        consumerfeatures={frontmatter.consumerfeatures}
        consumerfeaturesimg={frontmatter.consumerfeaturesimg}
        enterprisefeatures={frontmatter.enterprisefeatures}
        cta={frontmatter.cta}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        description
        usecases {
          icon
          heading
          description
        }
        datafeed {
          img
          title
          info
          origin
          certs
          eventfeed
        }
        features {
          heading
          description
        }
        carousel {
          img
          overlay
          description
        }
        consumerfeatures {
          icon
          heading
          description
        }
        consumerfeaturesimg
        enterprisefeatures {
          heading
          description
        }
        cta {
          img
          heading
          description
          linklabel
          link
        }
      }
    }
  }
`
